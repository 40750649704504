<template>
  <div id="openings">
    <NavbarWhite />
    <div class="bkgd">
      <h1>Job Openings</h1>
    </div>
    <div class="role">
      <div class="tw-flex justify-space-between role-header">
        <h2>{{ currentJob.name }}</h2>
        <v-btn class="upper-btn">Apply</v-btn>
      </div>
      <div class="desc">
        <p class="headings">Description</p>
        <p>
          {{ currentJob.desc }}
        </p>
      </div>
      <div class="requirements">
        <p class="headings">Requirements</p>
        <ul v-for="(item, index) in currentJob.requirements" :key="index">
          <li>{{ item.one }}</li>
        </ul>
      </div>
      <div class="sow">
        <p class="headings">Scope Of Work</p>
        <ul v-for="(scope, index) in currentJob.scope" :key="index">
          <li>{{ scope.one }}</li>
        </ul>
      </div>
      <div class="benefits">
        <p class="headings">Benefits</p>
        <ul v-for="(item, index) in currentJob.benefits" :key="index">
          <li>{{ item.one }}</li>
        </ul>
      </div>
      <div class="lower-btn">
        <v-btn>Apply</v-btn>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import NavbarWhite from "@/home/NavbarWhite.vue";
import Footer from "@/home/Footer.vue";
export default {
  components: {
    NavbarWhite,
    Footer,
  },
  data() {
    return {
      currentJob: {
        name: "",
        desc: "",
        requirements: [],
        scope: [],
        benefits: [],
      },
      jobs: [
        {
          name: "Product Designer",
          desc: "A Product Designer develops and designs consumer products. They build designs for new Products, add improvements to existing ones and conducts market resesrch for new product design ideas.",

          requirements: [
            {
              one: "Relevant work experience as a Product Designer",
            },
            {
              one: "Excellent eye for aesthetic design and attention to detail",
            },
            {
              one: "Exceptional verbal, written communication and presentation skills",
            },
            {
              one: "Critical Thinker, Innnovative and problem-solving skills ",
            },
            { one: "Strong knowledge of the industry and market trends" },
          ],
          scope: [
            {
              one: "Create design concept to determine the best product ",
            },
            {
              one: "Understand consumer trends to identify desirable product traits",
            },
            {
              one: "Work closely with other designers, engineers and the team ",
            },
            {
              one: "Coordinate with designers to ensure accurate communication and efficiency in the design phase",
            },
            { one: "Maintain detailed design documentation" },
          ],
          benefits: [
            {
              one: "A hybrid workplace ",
            },
            {
              one: "Reliable Health Insurance and Gym Membership ",
            },
            {
              one: "A comfortable work environment ",
            },
            {
              one: "Paid annual leave ",
            },
            { one: "Monthly fun activities and an Amazing Team Members" },
          ],
        },

        {
          name: "Frontend Developer",
          desc: "A Front End Developer is responsible for optimizing websites for different formats like desktops and mobile phones. A tech-savvy professional,  curious about new digital technologies and aspires to combine usability with visual design",

          requirements: [
            {
              one: "Relevant work experience as a Front end developer",
            },
            {
              one: "Familiarity with browser testing and debugging",
            },
            {
              one: "Proficiency in website programming languages such as HTML, CSS, Jvascript, Vuejs ",
            },
            {
              one: "Critical Thinker, analytical and problem-solving skills",
            },
            {
              one: "Understanding web development process and knowledge of SEO principles",
            },
          ],
          scope: [
            {
              one: "Work with back-end developers, product designers and Product Managers to improve usability",
            },
            {
              one: "Writing web codes with programming languages such as HTML, CSS, Jvascript, Vuejs ",
            },

            {
              one: "Debug errors, troubleshoot issues, and perform routine performance optimizations",
            },
            {
              one: "Stay up to date on emerging technologies and industry trends",
            },
          ],
          benefits: [
            {
              one: "A hybrid workplace ",
            },
            {
              one: "Reliable Health Insurance and Gym Membership ",
            },
            {
              one: "A comfortable work environment ",
            },
            {
              one: "Paid annual leave ",
            },
            { one: "Monthly fun activities and an Amazing Team Members" },
          ],
        },

      ],
    };
  },
  created() {
    let jobname = this.$route.params.jobname;
    let currentJob = {}
    if (jobname){
      this.jobs.map((job) => {
        if (job.name.toLowerCase() === jobname.toLowerCase()) {
          currentJob = job;
        }
      });
      if(!currentJob.name){
        this.$router.push({name:'errorpage'})
      }
      else {
        this.currentJob = currentJob
      }
    }
    else {
      this.$router.push({name:'errorpage'})
    }

  },
};
</script>

<style lang="scss" scoped>
.bkgd {
  min-height: 70vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.85)),
    url("../../assets/careerpictures/pic1.png");
  background-size: cover;
  background-color: rgba(16, 16, 13, 0.175);
  @media (max-width: 768px) {
    background-position: center;
    min-height: 30vh;
  }
}
.bkgd h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  padding-left: 12rem;
  padding-top: 20%;
  color: #ffffff;
  @media (max-width: 768px) {
    text-align: center;
    padding-top: 10rem;
    padding-left: 0;
  }
}
.role {
  min-height: 100vh;
  background: #ffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0 20%;
  position: relative;
  top: -100px;
  padding: 70px;
  @media (max-width: 768px) {
    margin: 0;
    position: initial;
  }
  @media (max-width: 576px) {
    padding: 3rem;
  }
}
.role-header {
  padding-bottom: 60px;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}
.role div {
  margin: 30px 0;
}
.v-btn {
  border: 1px solid #004aad;
  border-radius: 6px;
  background-color: transparent;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #004aad;
}
.upper-btn {
  @media (max-width: 576px) {
    margin-top: 30px;
  }
}
.role h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
}
.headings {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 40px;
  color: #3f3356;
}
.role li {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  list-style: disc;
  color: #4f4f4f;
}
.role p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #4f4f4f;
}
.lower-btn {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
</style>